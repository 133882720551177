/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CreateUserQuery model module.
 * @module model/CreateUserQuery
 * @version 0.0.1
 */
class CreateUserQuery {
    /**
     * Constructs a new <code>CreateUserQuery</code>.
     * @alias module:model/CreateUserQuery
     */
    constructor() { 
        
        CreateUserQuery.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CreateUserQuery</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateUserQuery} obj Optional instance to populate.
     * @return {module:model/CreateUserQuery} The populated <code>CreateUserQuery</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateUserQuery();

            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} label
 */
CreateUserQuery.prototype['label'] = undefined;

/**
 * @member {String} email
 */
CreateUserQuery.prototype['email'] = undefined;

/**
 * @member {String} notes
 */
CreateUserQuery.prototype['notes'] = undefined;

/**
 * @member {String} role
 */
CreateUserQuery.prototype['role'] = undefined;






export default CreateUserQuery;

