<template>
  <div>
    <div>
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between">
          <span>Machine Base File</span>
          <div v-if="isUserAdmin">
            <div class="btn-group">
              <b-button v-b-modal.editScreen size="sm" variant="outline-dark">
                <font-awesome-icon icon="edit"/>
                Edit
              </b-button>
            </div>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="barcode"/>
              S/N
            </div>
            <div class="float-right"><span>{{ machine.basefile.serial }}</span></div>
          </li>
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="tags"/>
              Type
            </div>
            <div class="float-right"><span>{{ machine.basefile.type }}</span></div>
          </li>
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="calendar-alt"/>
              CWR Date
            </div>
            <div class="float-right">
              <div v-if="machine.latest.cwr">
                <span>{{ machine.latest.cwr.timestamp }}</span>
              </div>
              <span v-else class="text-muted">(no data)</span>
            </div>
          </li>
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="briefcase"/>
              Owner
            </div>
            <div class="float-right"><span>{{ machine.basefile.owner }}</span></div>
          </li>
          <li v-if="machine.basefile.coverimage" class="list-group-item">
            <font-awesome-icon icon="camera"/>
            Picture<br>
            <img :src="'/report/' + machine.basefile.coverimage"
                 alt="Cover image" class="mt-2" style="width: 100%; height: auto; border: 1px solid black;">
          </li>
          <li v-if="machine.basefile.lat" class="list-group-item">
            <font-awesome-icon icon="globe-americas"/>
            Location<br>
            <iframe :src="'https://www.openstreetmap.org/export/embed.html?marker=' + machine.basefile.lat + '%2C' + machine.basefile.lon" class="mt-2"
                    frameborder="0" height="200" marginheight="0"
                    marginwidth="0"
                    scrolling="no" style="border: 1px solid black" width="100%"></iframe>
          </li>
        </ul>
      </div>
      <div class="card">
        <div class="card-header">
          Key Documents
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="file"/>
              Manual
            </div>
            <div class="float-right">
              <div v-if="machine.latest.manual">
                <a :href="'/entry/' + machine.latest.manual.longid + '/' + machine.latest.manual.filename">{{
                    machine.latest.manual.filename
                  }}</a>
              </div>
              <span v-else class="text-muted">(no data)</span>
            </div>
          </li>
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="list-alt"/>
              Assembly Log
            </div>
            <div class="float-right">
              <div v-if="machine.latest.assemblylog">
                <a :href="'/entry/' + machine.latest.assemblylog.longid + '/' + machine.latest.assemblylog.filename">{{
                    machine.latest.assemblylog.filename
                  }}</a>
              </div>
              <span v-else class="text-muted">(no data)</span>
            </div>
          </li>
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="weight"/>
              CWR
            </div>
            <div class="float-right">
              <div v-if="machine.latest.cwr">
                <a :href="'/entry/' + machine.latest.cwr.longid + '/' + machine.latest.cwr.filename">{{
                    machine.latest.cwr.filename
                  }}</a>
              </div>
              <span v-else class="text-muted">(no data)</span>
            </div>
          </li>
          <li class="list-group-item">
            <div class="float-left">
              <font-awesome-icon icon="weight"/>
              Latest Report
            </div>
            <div class="float-right">
              <div v-if="machine.latest.report">
                <router-link :to="'/report/' + machine.latest.report.longid">
                  {{ machine.latest.report.filename ? machine.latest.report.filename : 'Unlabeled' }}
                </router-link>
              </div>
              <span v-else class="text-muted">(no data)</span>
            </div>
          </li>
        </ul>
      </div>
      <div id="documentArchive" class="card">
        <div class="card-header">
          Document Archive
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a v-b-toggle.collapse-reports style="cursor: pointer">
              <font-awesome-icon class="auto" icon="folder-open"/>
              <font-awesome-icon class="auto" icon="folder"/>
              ScreenCheck Reports
            </a>
            <b-collapse id="collapse-reports" visible>
              <ul>
                <li v-for="report in machine.docsReports" :key="report.longid">
                  <router-link :to="'/report/' + report.longid">
                    <font-awesome-icon class="mr-2" icon="file"/>
                    <span v-if="report.filename">
                      {{ report.filename }}
                    </span>
                    <span v-else>
                      Unlabeled
                    </span>
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li v-if="isUserAdmin" class="list-group-item">
            <a v-b-toggle.collapse-hidden-reports style="cursor: pointer">
              <font-awesome-icon class="auto" icon="folder-open"/>
              <font-awesome-icon class="auto" icon="folder"/>
              Hidden ScreenCheck Reports
            </a>
            <b-collapse id="collapse-hidden-reports">
              <ul>
                <li v-for="report in machine.docsReportsHidden" :key="report.longid">
                  <router-link :to="'/report/' + report.longid">
                    <font-awesome-icon class="mr-2" icon="file"/>
                    <span v-if="report.filename">
                      {{ report.filename }}
                    </span>
                    <span v-else>
                      Unlabeled
                    </span>
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="list-group-item">
            <a v-b-toggle.collapse-attachments style="cursor: pointer">
              <font-awesome-icon class="auto" icon="folder-open"/>
              <font-awesome-icon class="auto" icon="folder"/>
              Uploaded Attachments
            </a>
            <b-collapse id="collapse-attachments">
              <ul>
                <li v-for="(item, index) in attachments" :key="index">
                  <a :href="'/entry/' + item.entry.longid + '/' + item.entry.filename">
                    <font-awesome-icon class="mr-2" icon="file"/>
                    {{ item.entry.filename }}
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
        </ul>
      </div>
    </div>
    <!-- Edit Machine Modal -->
    <b-modal id="editScreen" title="Edit" @cancel="resetModalData" @ok="editMachine">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">S/N</label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon icon="barcode"/></span></div>
            <b-form-input id="serial" v-model="modalMachine.serial" style="text-transform:uppercase" trim @keypress="serialKeyPress"></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Type</label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><font-awesome-icon icon="tags"/></span>
            </div>
            <b-form-input id="type" v-model="modalMachine.type" name="type" trim type="text"></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Owner</label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon icon="briefcase"/></span></div>
            <b-form-input id="owner" v-model="modalMachine.owner" name="owner" trim type="text"></b-form-input>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { DetailedMachineInformation, EditMachineQuery } from '@/screencheck_portal_api/src'
import { machinesApi } from '@/backend'

export default {
  name: 'MachineInfo',
  props: {
    machine: {
      type: DetailedMachineInformation
    }
  },
  components: {},
  data () {
    return {
      modalMachine: {
        owner: '',
        type: '',
        serial: ''
      }
    }
  },
  mounted () {
    this.resetModalData()
  },
  computed: {
    attachments: function () {
      return this.machine.items.filter(item => item.isEntry && item.entry.filename)
    },
    isUserAdmin: function () {
      return this.$store.getters.isUserAdmin
    },
  },
  methods: {
    resetModalData: function () {
      this.modalMachine.owner = this.machine.basefile.owner
      this.modalMachine.type = this.machine.basefile.type
      this.modalMachine.serial = this.machine.basefile.serial
    },
    editMachine: function () {
      const newSerial = this.cleanSerial(this.modalMachine.serial)
      let editQuery = EditMachineQuery.constructFromObject({
        'serial': newSerial,
        'type': this.modalMachine.type,
        'owner': this.modalMachine.owner,
      })
      machinesApi.editMachine(this.machine.basefile.serial, editQuery, (error) => {
        if (error) {
          console.error('Failed editing machine because: ' + error)
          return
        }
        this.$emit('baseFileEdit', newSerial)
      })
    },
    serialKeyPress: function (ev) {
      if (String.fromCharCode(ev.which).match(/[^a-zA-Z0-9 .-]/g) && event.keyCode !== 46 && event.keyCode !== 8) {
        ev.preventDefault()
      }
    },
    cleanSerial: function (serial) {
      return serial.replace(/[^a-zA-Z0-9 .-]/g, '').toUpperCase()
    }
  }
}
</script>

<style lang="scss">
</style>
