import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignLeft,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBriefcase,
  faCalendarAlt,
  faCamera,
  faCheck,
  faCheckCircle,
  faCloudUploadAlt,
  faCommentAlt,
  faDownload,
  faEdit,
  faEllipsisV,
  faExchangeAlt,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileCode,
  faFileCsv,
  faFolder,
  faFolderOpen,
  faGlassMartini,
  faGlobeAmericas,
  faImage,
  faInfoCircle,
  faLink,
  faUnlink,
  faList,
  faListAlt,
  faMobileAlt,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faSearch,
  faShare,
  faShareSquare,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTachometerAlt,
  faTag,
  faTags,
  faTimes,
  faTimesCircle,
  faUser,
  faUserCog,
  faUserLock,
  faUsersCog,
  faWeight,
  faWrench
} from '@fortawesome/free-solid-svg-icons'

library.add(faAlignLeft)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faBarcode)
library.add(faBriefcase)
library.add(faCalendarAlt)
library.add(faCamera)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faCloudUploadAlt)
library.add(faCommentAlt)
library.add(faDownload)
library.add(faEdit)
library.add(faEllipsisV)
library.add(faExchangeAlt)
library.add(faExclamationCircle)
library.add(faEye)
library.add(faEyeSlash)
library.add(faFile)
library.add(faFileCode)
library.add(faFileCsv)
library.add(faFolder)
library.add(faFolderOpen)
library.add(faGlassMartini)
library.add(faGlobeAmericas)
library.add(faImage)
library.add(faInfoCircle)
library.add(faLink)
library.add(faUnlink)
library.add(faList)
library.add(faListAlt)
library.add(faMobileAlt)
library.add(faPlus)
library.add(faPlusCircle)
library.add(faQuestionCircle)
library.add(faSave)
library.add(faSearch)
library.add(faShare)
library.add(faShareSquare)
library.add(faSignInAlt)
library.add(faSignOutAlt)
library.add(faSort)
library.add(faSortDown)
library.add(faSortUp)
library.add(faSync)
library.add(faTachometerAlt)
library.add(faTag)
library.add(faTags)
library.add(faTimes)
library.add(faTimesCircle)
library.add(faUser)
library.add(faUserCog)
library.add(faUserLock)
library.add(faUsersCog)
library.add(faWeight)
library.add(faWrench)

export default FontAwesomeIcon
