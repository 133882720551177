<template>
  <b-table
      id="active-users-table"
      :busy="isBusy"
      :fields="fields"
      :filter="filter"
      :items="users"
      :responsive="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      small
      striped
  >
    <template #head(role)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('role', $event)">
            Role
            <font-awesome-icon :icon="getSortByClass('role')"/>
          </a>
        </span>
    </template>
    <template #cell(role)="data">
      <font-awesome-icon v-if="data.item.role === 'restricted'" icon="user-lock" title="User"/>
      <font-awesome-icon v-else-if="data.item.role === 'user'" icon="user" title="User"/>
      <font-awesome-icon v-else-if="data.item.role === 'viewall'" icon="eye" title="User"/>
      <font-awesome-icon v-else-if="data.item.role === 'admin'" icon="user-cog" title="User"/>
      <span v-else>{{ data.item.role }}</span>
    </template>
    <template #head(details)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('label', $event)">
            Name
            <font-awesome-icon :icon="getSortByClass('label')"/>
          </a>
        </span><br>
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('email', $event)">
            E-Mail
            <font-awesome-icon :icon="getSortByClass('email')"/>
          </a>
        </span><br>
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('notes', $event)">
            Notes
            <font-awesome-icon :icon="getSortByClass('notes')"/>
          </a>
        </span>
    </template>
    <template #cell(details)="data">
      <div :title="data.item.longid">{{ data.item.label }}</div>
      <div class="secondary"><a :href="'mailto:' + data.item.email">{{ data.item.email }}</a></div>
      <div class="secondary" style="white-space:pre-wrap">{{ data.item.notes }}</div>
    </template>
    <template #head(activity)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('lastReport', $event)">
            Last Report
            <font-awesome-icon :icon="getSortByClass('lastReport')"/>
          </a>
        </span><br>
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('lastLogin', $event)">
            Last Activity
            <font-awesome-icon :icon="getSortByClass('lastLogin')"/>
          </a>
        </span>
    </template>
    <template #cell(activity)="data">
      <div>{{ data.item.lastReport || 'N/A' }}</div>
      <div class="secondary">{{ data.item.lastLogin || 'never' }}</div>
    </template>
    <template #head(reports)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('recentReportCount', $event)">
            Recent Reports
			<font-awesome-icon :icon="getSortByClass('recentReportCount')"/>
          </a>
        </span><br>
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('reportCount', $event)">
            Total Reports
			<font-awesome-icon :icon="getSortByClass('reportCount')"/>
          </a>
        </span>
    </template>
    <template #cell(reports)="data">
      <div>{{ data.item.recentReportCount }} recent</div>
      <div class="secondary">{{ data.item.reportCount }} total</div>
    </template>
    <template #head(tablet)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('deviceModel', $event)">
            Tablet
            <font-awesome-icon :icon="getSortByClass('deviceModel')"/>
          </a>
        </span><br>
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('softwareVersion', $event)">
            ScreenCheck
            <font-awesome-icon :icon="getSortByClass('softwareVersion')"/>
          </a>
        </span>
    </template>
    <template #cell(tablet)="data">
      <div :title="data.item.samsungSerial">
        {{ data.item.deviceModel }} <span v-if="data.item.androidVersion">({{ data.item.androidVersion }})</span>
      </div>
      <div>
        <span v-if="data.item.softwareVersion">SC {{ data.item.softwareVersion }}</span>
      </div>
    </template>
    <template #head(sensor)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('sensorName', $event)">
            Sensor
            <font-awesome-icon :icon="getSortByClass('sensorName')"/>
          </a>
        </span><br>
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('sensorFirmware', $event)">
            Firmware
            <font-awesome-icon :icon="getSortByClass('sensorFirmware')"/>
          </a>
        </span>
    </template>
    <template #cell(sensor)="data">
      <div>{{ data.item.sensorName }}</div>
      <div>
        <span v-if="data.item.sensorFirmware">FW {{ data.item.sensorFirmware }}</span>
      </div>
    </template>
    <template #head(linked)="">
      <span class="text-muted font-weight-normal" style="white-space:nowrap;">
          <a href="#" @click="setSortBy('linked', $event)">
            User Linked
            <font-awesome-icon :icon="getSortByClass('linked')"/>
          </a>
        </span>
    </template>
    <template #cell(linked)="data">
      <div v-if="data.item.linked">
        <font-awesome-icon icon="link" class="text-success" title="Linked to a Metso Outotec Account"/>
      </div>
      <div v-else>
        <font-awesome-icon icon="unlink" class="text-muted" title="Not linked to a Metso Outotec Account"/>
      </div>
    </template>
    <template #head(edit)="">
      <span></span>
    </template>
    <template #cell(edit)="data">
      <b-dropdown variant="outline-secondary">
        <template #button-content>
          <font-awesome-icon icon="ellipsis-v" title="Menu"/>
        </template>
        <b-dropdown-item>
          <a class="dropdown-item editUser" href="#" title="Edit" @click="editUser(data.item.longid, $event)">
            <font-awesome-icon icon="edit"/>
            edit
          </a>
        </b-dropdown-item>
        <b-dropdown-item>
          <a class="dropdown-item statusToggle" href="#" title="Toggle" @click="toggleActive(data.item.longid, $event)">
            <font-awesome-icon icon="edit"/>
            set <span v-if="data.item.status === 'active'">inactive</span><span v-else>active</span>
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template #empty="">
      <div aria-live="polite" role="alert">
        <div class="text-center my-2">
          <b-spinner v-if="isBusy" :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..."
                     variant="secondary"></b-spinner>
          <span v-else>There are no records matching your request</span>
        </div>
      </div>
    </template>
    <template #emptyfiltered="">
      <div aria-live="polite" role="alert">
        <div class="text-center my-2">
          <span>There are no records matching your request</span>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'UsersTable',
  props: [
    'users',
    'isBusy',
    'filter'
  ],
  data: function () {
    return {
      fields: [
        { key: 'role' },
        { key: 'details' },
        { key: 'activity' },
        { key: 'reports' },
        { key: 'tablet' },
        { key: 'sensor' },
        { key: 'linked' },
        { key: 'edit' }
      ],
      sortBy: null,
      sortDesc: false,
    }
  },
  methods: {
    setSortBy: function (sortBy, event) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortBy = sortBy
        this.sortDesc = false
      }
      if (event) {
        // Prevent jumping to the top of the page
        event.preventDefault()
      }
    },
    getSortByClass: function (btnSortBy) {
      if (btnSortBy === this.sortBy) {
        return (this.sortDesc ? 'sort-down' : 'sort-up')
      } else {
        return 'sort'
      }
    },
    editUser: function (userLongid, event) {
      this.$emit('editUser', userLongid)
      if (event) {
        // Prevent jumping to the top of the page
        event.preventDefault()
      }
    },
    toggleActive: function (userLongid, event) {
      this.$emit('toggleActive', userLongid)
      if (event) {
        // Prevent jumping to the top of the page
        event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss">
</style>
