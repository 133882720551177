import { ApiClient, DeviceLogsApi, DevicesApi, LogApi, MachinesApi, ReportsApi, StatsApi, TokenApi, UsersApi, UpdateApi } from './screencheck_portal_api/src'

const client = new ApiClient()
client.basePath = window.location.origin
// USE this if you are using a Chrome based Browser
client.defaultHeaders['User-Agent'] = null

// Set Timeout to 5 minutes, mainly implemented for https://pm.jcbachmann.com/T15775
client.timeout = 300000

const devicesApi = new DevicesApi(client)
const deviceLogsApi = new DeviceLogsApi(client)
const logApi = new LogApi(client)
const machinesApi = new MachinesApi(client)
const reportsApi = new ReportsApi(client)
const statsApi = new StatsApi(client)
const tokenApi = new TokenApi(client)
const usersApi = new UsersApi(client)
const updateApi = new UpdateApi(client)

export {
  devicesApi,
  deviceLogsApi,
  logApi,
  machinesApi,
  reportsApi,
  statsApi,
  tokenApi,
  usersApi,
  updateApi
}
