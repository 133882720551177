<template>
  <div>
    <h1 id="headline" class="page-header">My Devices</h1>
    <b-table :fields="visibleFields" :items="tokens" :tbody-tr-class="rowClass">
      <template #cell(label)="data">
        <button class="editToken btn btn-outline-dark btn-sm mr-1" type="button" @click="editToken(data.item)">
          <font-awesome-icon icon="edit"/>
        </button>
        <span>{{ data.item.label }}</span>
      </template>
      <template #cell(action)="data">
        <button :disabled="data.item.isCurrent" class="revokeToken btn btn-danger btn-sm float-right"
                title="Current device, use logout" type="button" @click="showRemoveTokenBox(data.item)">
          <font-awesome-icon icon="times"/>
          revoke
        </button>
      </template>
    </b-table>

    <b-button class="mb-3" variant="outline-dark" @click="toggleDetails">
      {{ detailsOpen ? 'Hide' : 'Show' }} Details
    </b-button>

    <div class="alert alert-info" role="alert">
      <font-awesome-icon icon="info-circle"/>
      You can rename your devices for easy identification by pressing the
      <font-awesome-icon icon="edit"/>
      button.
      If you see devices which you will not use
      again or were authenticated without your permission you can revoke their authentication.
    </div>
    <b-modal id="edit-device-modal" ref="edit-device-modal" :title="'Edit Device [' + this.deviceInfo.longid + ']'" @cancel="cancelEdit" @ok="submit">
      <div id="edit-device" class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="label">Label</label>
          <div class="col-sm-9">
            <input id="label" class="form-control" v-model="deviceInfo.label">
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { devicesApi } from '@/backend'
import { EditDeviceTokenQuery, RevokeDeviceTokenQuery } from '../screencheck_portal_api/src'

export default {
  name: 'DevicesPage',
  data () {
    return {
      statusText: '',
      tokens: null,
      fields: [
        { key: 'label', label: 'Label', sortable: true, visible: true },
        { key: 'longid', label: 'ID', sortable: true, visible: true },
        { key: 'lastUsage', label: 'Last used', sortable: true, visible: true },
        { key: 'expiry', label: 'Expiry', sortable: true, visible: true },
        { key: 'samsungSerial', sortable: true, visible: false },
        { key: 'androidVersion', sortable: true, visible: false },
        { key: 'softwareVersion', sortable: true, visible: false },
        { key: 'sensorName', sortable: true, visible: false },
        { key: 'sensorMac', sortable: true, visible: false },
        { key: 'sensorFirmware', sortable: true, visible: false },
        { key: 'action', label: '', sortable: false, visible: true }
      ],
      detailsOpen: false,
      deviceInfo: {
        longid: '',
        label: '',
      }
    }
  },
  created () {
    this.fetchTokens()
  },
  computed: {
    visibleFields () {
      return this.fields.filter(field => field.visible)
    }
  },
  methods: {
    toggleDetails: function () {
      let toggleAbleFields = ['samsungSerial', 'androidVersion', 'softwareVersion', 'sensorName', 'sensorMac', 'sensorFirmware']
      this.fields.forEach(field => {
        if (toggleAbleFields.includes(field.key)) {
          field.visible = !field.visible
        }
      })
      this.detailsOpen = !this.detailsOpen
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.is_current) return 'current-token-row'
    },
    fetchTokens: function () {
      devicesApi.getDeviceTokens((error, data) => {
        if (error) {
          console.error('Failed fetching device tokens because: ' + error)
          this.statusText = 'Error transferring device tokens'
          return
        }
        this.tokens = data.tokens
        this.statusText = null
      })
    },
    cancelEdit: function () {
      this.deviceInfo.longid = ''
      this.deviceInfo.label = ''
    },
    submit: function () {
      let updateQuery = EditDeviceTokenQuery.constructFromObject({
        tokenLongId: this.deviceInfo.longid.replace(' ', ''),
        tokenLabel: this.deviceInfo.label
      })
      devicesApi.updateDeviceTokenLabel(updateQuery, (error) => {
        if (error) {
          console.error('Failed updating device token label because: ' + error)
          return
        }
        this.fetchTokens()
      })
    },
    showRemoveTokenBox (device) {
      this.$bvModal.msgBoxConfirm(device.label + ' will not be able to access ScreenCheck Portal anymore.', {
        title: 'Revoking device access',
      }).then(value => {
        if (value) {
          let revokeQuery = RevokeDeviceTokenQuery.constructFromObject({
            tokenLongId: device.longid.replace(' ', '')
          })
          devicesApi.revokeDeviceToken(revokeQuery, (error) => {
            if (error) {
              console.error('Failed revoking device token because: ' + error)
              return
            }
            // fetch Data
            this.fetchTokens()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    editToken: function (row) {
      this.deviceInfo.longid = row.longid
      this.deviceInfo.label = row.label
      this.$refs['edit-device-modal'].show()
    }
  }
}
</script>

<style lang="scss">
</style>
