import { tokenApi } from '@/backend'

export default {
  state: {
    user: undefined
  },
  getters: {
    authValid: state => {
      return state.user !== undefined
    },
    isUserViewAll: state => {
      return state.user && state.user.is_viewall
    },
    isUserAdmin: state => {
      return state.user && state.user.is_admin
    },
    userLongid: state => {
      return state.user && state.user.longid
    },
    userLabel: state => {
      return state.user && state.user.label
    }
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    queryAuth: (context) => {
      const deleteUserTokenCookie = new Promise((resolve, reject) => {
        tokenApi.tokenOwnDelete((error) => {
          if (error) {
            reject(error)
            return
          }
          resolve()
        })
      })

      return new Promise((resolve, reject) => {
        if (context.state.user !== undefined) {
          resolve({ authenticated: true })
        } else {
          deleteUserTokenCookie.then(() => {
            tokenApi.tokenOwnUser((error, user) => {
              if (error) {
                if (error.status === 401) {
                  resolve({ authenticated: false })
                  return
                } else if (error.status === 403) {
                  resolve({ authenticated: false, response: error.response.body })
                  return
                } else {
                  reject(error)
                  return
                }
              }
              if (user !== undefined) {
                context.commit('setUser', user)
                resolve({ authenticated: true })
              } else {
                resolve({ authenticated: false, response: { message: 'Invalid user' } })
              }
            })
          }).catch((error) => {
            reject(error)
          })
        }
      })
    }
  }
}
