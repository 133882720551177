import { updateApi } from '@/backend'

export default {
  namespaced: true,
  state: {
    latestVersion: undefined
  },
  getters: {
    latestVersion: state => state.latestVersion
  },
  mutations: {
    setLatestVersion (state, version) {
      state.latestVersion = version
    }
  },
  actions: {
    fetchLatestVersion: (context) => {
      updateApi.updateCheck((error, response) => {
        if (error) {
          console.error(error)
          return
        }
        context.commit('setLatestVersion', response.version)
      })
    },
  }
}
