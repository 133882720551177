/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MachineBasefile from './MachineBasefile';
import MachineSerial from './MachineSerial';

/**
 * The DetailedMachineInformation model module.
 * @module model/DetailedMachineInformation
 * @version 0.0.1
 */
class DetailedMachineInformation {
    /**
     * Constructs a new <code>DetailedMachineInformation</code>.
     * @alias module:model/DetailedMachineInformation
     */
    constructor() { 
        
        DetailedMachineInformation.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DetailedMachineInformation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DetailedMachineInformation} obj Optional instance to populate.
     * @return {module:model/DetailedMachineInformation} The populated <code>DetailedMachineInformation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DetailedMachineInformation();

            if (data.hasOwnProperty('basefile')) {
                obj['basefile'] = MachineBasefile.constructFromObject(data['basefile']);
            }
            if (data.hasOwnProperty('docsReports')) {
                obj['docsReports'] = ApiClient.convertToType(data['docsReports'], [Object]);
            }
            if (data.hasOwnProperty('docsReportsHidden')) {
                obj['docsReportsHidden'] = ApiClient.convertToType(data['docsReportsHidden'], [Object]);
            }
            if (data.hasOwnProperty('entries')) {
                obj['entries'] = ApiClient.convertToType(data['entries'], [Object]);
            }
            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Object]);
            }
            if (data.hasOwnProperty('latest')) {
                obj['latest'] = ApiClient.convertToType(data['latest'], Object);
            }
            if (data.hasOwnProperty('otherSerials')) {
                obj['otherSerials'] = ApiClient.convertToType(data['otherSerials'], [MachineSerial]);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/MachineBasefile} basefile
 */
DetailedMachineInformation.prototype['basefile'] = undefined;

/**
 * @member {Array.<Object>} docsReports
 */
DetailedMachineInformation.prototype['docsReports'] = undefined;

/**
 * @member {Array.<Object>} docsReportsHidden
 */
DetailedMachineInformation.prototype['docsReportsHidden'] = undefined;

/**
 * @member {Array.<Object>} entries
 */
DetailedMachineInformation.prototype['entries'] = undefined;

/**
 * @member {Array.<Object>} items
 */
DetailedMachineInformation.prototype['items'] = undefined;

/**
 * @member {Object} latest
 */
DetailedMachineInformation.prototype['latest'] = undefined;

/**
 * @member {Array.<module:model/MachineSerial>} otherSerials
 */
DetailedMachineInformation.prototype['otherSerials'] = undefined;






export default DetailedMachineInformation;

