<template>
  <div>
    <h1 id="headline" class="page-header">Token Requests
      <b-button variant="link" class="info-link" @click="showTokensAlert=true">
        <font-awesome-icon icon="info-circle" size="2x"/>
      </b-button>
    </h1>
    <b-alert v-model="showTokensAlert" dismissible variant="secondary">
      <p>
        <font-awesome-icon icon="info-circle" class="mr-1"/>
        <strong>Information</strong></p>
      <p>This is the token request approval section. Here you can manage access to ScreenCheck Portal for new devices.</p>
      <p>If a device with a legacy version of ScreenCheck App connects to the system
        a token request is created. The request can be accepted or rejected by an <strong>administrator</strong>.</p>
      <p>This list shows the unapproved tokens (open token requests).</p>
      <p>You can choose to <strong>authorize</strong> a token and link it with a user. This allows the device to
        log in as that user and synchronize reports. Use this option for legitimate requests.</p>
      <p>If you chose <strong>invalidate</strong> the request is cancelled and the token is marked invalid. The device
        where the request was issued will not be able to log in and the request will be hidden from this list. Use this
        for accidental / duplicate and illegitimate requests.</p>
      <p>When authorizing a token you can set an expiration date at which the token is automatically invalidated. This
        will allow the user to log in up to that point in time. This can be used to give temporary access to the system
        on non-personal machines or for demonstration purposes.</p>
      <p class="mb-0">
        <font-awesome-icon icon="share"/>
        More information on various administrative situations can be
        found in the <a class="alert-link" href="/admin/guide">ScreenCheck Portal Admin Guide</a>.
      </p>
    </b-alert>
    <b-table :fields="fields" :items="tokens">
      <template #cell(action)="data">
        <button class="invalidateToken btn btn-danger btn-sm float-right ml-2" type="button"
                @click="showInvalidateTokenBox(data.item.activationcode)">
          <font-awesome-icon icon="times"/>
          invalidate
        </button>
        <button class="editToken btn btn-success btn-sm float-right" type="button"
                @click="editToken(data.item.activationcode, data.item.label)">
          <font-awesome-icon icon="check"/>
          authorize
        </button>
      </template>
    </b-table>
    <b-modal id="authorize-token-modal" ref="authorize-token-modal" title="Authorize Token" @cancel="cancelEdit" @ok="submit">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="label">Label</label>

        <div class="col-sm-9">
          <input id="label" class="form-control" readonly v-model="label">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="activationcode">Activation Code</label>

        <div class="col-sm-9">
          <input id="activationcode" class="activationcode-edit form-control" readonly v-model="activationCode">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="user">User</label>

        <div class="col-sm-9">
          <select id="user" class="form-control" v-model="user">
            <option value="null">please select user</option>
            <option v-for="user in users" :key="user.longid" :value="user.longid">{{ user.label }} [{{ user.longid }}]</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="expiry">Expiry</label>

        <div class="col-sm-9">
          <input id="expiry" class="form-control" data-provide="datepicker" placeholder="never" v-model="expiry">
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { tokenApi } from '@/backend'
import { TokenRequestQuery } from '../screencheck_portal_api/src'

export default {
  name: 'TokensPage',
  data () {
    return {
      stats: null,
      statusText: '',
      tokens: [],
      users: [],
      fields: [
        { key: 'label', label: 'Label', sortable: true },
        { key: 'activationcode', label: 'Activation Code', sortable: true },
        { key: 'expiry', label: 'Expiry', sortable: true },
        { key: 'action', label: '', sortable: false }
      ],
      showTokensAlert: false,
      activationCode: null,
      user: null,
      expiry: null,
      label: null,
    }
  },
  created () {
    this.fetchTokens()
  },
  methods: {
    showInvalidateTokenBox (activationCode) {
      this.$bvModal.msgBoxConfirm('The device for activation code ' + activationCode + ' will not be able to access ScreenCheck Portal.', {
        title: 'Invalidating token request',
      }).then(conformation => {
        if (conformation) {
          const tokenRequestQuery = TokenRequestQuery.constructFromObject({
            activationcode: activationCode.replace(' ', '')
          })
          tokenApi.tokenRevoke(tokenRequestQuery, (error) => {
            if (error) {
              console.log(error)
              return
            }
            // fetch Data
            this.fetchTokens()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    fetchTokens: function () {
      tokenApi.getTokens((error, data) => {
        if (error) {
          this.statusText = 'Error transferring tokens'
          return
        }
        this.users = data.users
        this.tokens = data.tokens
        this.statusText = null
      })
    },
    editToken: function (activationCode, label) {
      this.label = label
      this.activationCode = activationCode
      this.$refs['authorize-token-modal'].show()
    },
    cancelEdit: function () {
      this.activationCode = null
      this.user = null
      this.expiry = null
      this.label = null
    },
    submit: function () {
      const tokenRequestQuery = TokenRequestQuery.constructFromObject({
        user: this.user,
        activationcode: this.activationCode.replace(' ', ''),
        expiry: this.expiry
      })
      tokenApi.tokenAuth(tokenRequestQuery, (error) => {
        if (error) {
          console.log(error)
          return
        }
        this.fetchTokens()
      })
    },
  }
}
</script>

<style lang="scss">
</style>
