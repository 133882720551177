import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '../views/Auth.vue'
import DeviceLogs from '../views/DeviceLogs.vue'
import Devices from '../views/Devices.vue'
import Log from '../views/Log.vue'
import Machine from '../views/Machine.vue'
import Machines from '../views/Machines.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Report from '../views/Report.vue'
import Reports from '../views/Reports.vue'
import Stats from '../views/Stats.vue'
import Tokens from '../views/Tokens.vue'
import Users from '../views/Users.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin/device-logs',
    name: 'device-logs',
    component: DeviceLogs,
    meta: {
      title: 'Device Logs',
      requiresAuth: true
    }
  },
  {
    path: '/devices',
    name: 'devices',
    component: Devices,
    meta: {
      title: 'My Devices',
      requiresAuth: true
    }
  },
  {
    path: '/log',
    name: 'log',
    component: Log,
    meta: {
      title: 'Log',
      requiresAuth: true
    }
  },
  {
    path: '/machine/:serial',
    name: 'machine',
    component: Machine,
    meta: {
      title: 'Screen',
      requiresAuth: true
    }
  },
  {
    path: '/machines',
    name: 'machines',
    component: Machines,
    meta: {
      title: 'All Screens',
      requiresAuth: true
    }
  },
  {
    path: '/report/:longid/:sharetoken?',
    name: 'report',
    component: Report,
    meta: {
      title: 'Report',
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'my-reports',
    component: Reports,
    meta: {
      title: 'My Reports',
      requiresAuth: true
    },
    props: () => ({
      isAllReports: false
    })
  },
  {
    path: '/reports/all',
    name: 'all-reports',
    component: Reports,
    meta: {
      title: 'All Reports',
      requiresAuth: true
    },
    props: () => ({
      isAllReports: true
    })
  },
  {
    path: '/admin/stats',
    name: 'stats',
    component: Stats,
    meta: {
      title: 'Admin Stats',
      requiresAuth: true
    }
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: Tokens,
    meta: {
      title: 'Tokens',
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Users',
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: '/',
    redirect: '/reports'
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      title: 'Authentication',
      requiresAuth: false
    }
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
    meta: {
      title: 'Page Not Found',
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch('queryAuth')
      .then(({ authenticated }) => {
        if (authenticated) {
          next()
        } else {
          next({ name: 'auth' })
        }
      })
      .catch((error) => {
        console.error(error)
        next({ name: 'auth' })
      })
  } else {
    next()
  }
})

router.afterEach((to) => {
  document.title = to.meta.title ? to.meta.title + ' - ScreenCheck Portal' : 'ScreenCheck Portal'
})

export default router
