/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommentReportQuery from '../model/CommentReportQuery';
import EditReportShareTokenQuery from '../model/EditReportShareTokenQuery';
import Error from '../model/Error';
import MoveReportQuery from '../model/MoveReportQuery';
import ReportsResponse from '../model/ReportsResponse';
import RevokeReportShareTokenQuery from '../model/RevokeReportShareTokenQuery';

/**
* Reports service.
* @module api/ReportsApi
* @version 0.0.1
*/
export default class ReportsApi {

    /**
    * Constructs a new ReportsApi. 
    * @alias module:api/ReportsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the commentReport operation.
     * @callback module:api/ReportsApi~commentReportCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} reportLongId The long id of the report to comment
     * @param {module:model/CommentReportQuery} commentReportQuery The Query contains the comment
     * @param {module:api/ReportsApi~commentReportCallback} callback The callback function, accepting three arguments: error, data, response
     */
    commentReport(reportLongId, commentReportQuery, callback) {
      let postBody = commentReportQuery;
      // verify the required parameter 'reportLongId' is set
      if (reportLongId === undefined || reportLongId === null) {
        throw new Error("Missing the required parameter 'reportLongId' when calling commentReport");
      }
      // verify the required parameter 'commentReportQuery' is set
      if (commentReportQuery === undefined || commentReportQuery === null) {
        throw new Error("Missing the required parameter 'commentReportQuery' when calling commentReport");
      }

      let pathParams = {
        'reportLongId': reportLongId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/report/{reportLongId}/comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllReports operation.
     * @callback module:api/ReportsApi~getAllReportsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ReportsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.filter 
     * @param {String} opts.sortBy 
     * @param {Boolean} opts.sortDesc 
     * @param {Number} opts.currentPage 
     * @param {Number} opts.perPage 
     * @param {Boolean} opts.doFullTextSearch 
     * @param {module:api/ReportsApi~getAllReportsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ReportsResponse}
     */
    getAllReports(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter'],
        'sortBy': opts['sortBy'],
        'sortDesc': opts['sortDesc'],
        'currentPage': opts['currentPage'],
        'perPage': opts['perPage'],
        'doFullTextSearch': opts['doFullTextSearch']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ReportsResponse;
      return this.apiClient.callApi(
        '/reports/all/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMyReports operation.
     * @callback module:api/ReportsApi~getMyReportsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ReportsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.filter 
     * @param {String} opts.sortBy 
     * @param {Boolean} opts.sortDesc 
     * @param {Number} opts.currentPage 
     * @param {Number} opts.perPage 
     * @param {Boolean} opts.doFullTextSearch 
     * @param {module:api/ReportsApi~getMyReportsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ReportsResponse}
     */
    getMyReports(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter'],
        'sortBy': opts['sortBy'],
        'sortDesc': opts['sortDesc'],
        'currentPage': opts['currentPage'],
        'perPage': opts['perPage'],
        'doFullTextSearch': opts['doFullTextSearch']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ReportsResponse;
      return this.apiClient.callApi(
        '/reports/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getReportWithShareToken operation.
     * @callback module:api/ReportsApi~getReportWithShareTokenCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns the report data of a specified report
     * @param {String} reportLongId long id of the report to return
     * @param {String} shareToken the share token of the report
     * @param {module:api/ReportsApi~getReportWithShareTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getReportWithShareToken(reportLongId, shareToken, callback) {
      let postBody = null;
      // verify the required parameter 'reportLongId' is set
      if (reportLongId === undefined || reportLongId === null) {
        throw new Error("Missing the required parameter 'reportLongId' when calling getReportWithShareToken");
      }
      // verify the required parameter 'shareToken' is set
      if (shareToken === undefined || shareToken === null) {
        throw new Error("Missing the required parameter 'shareToken' when calling getReportWithShareToken");
      }

      let pathParams = {
        'reportLongId': reportLongId,
        'shareToken': shareToken
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/report/{reportLongId}/data/{shareToken}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getReportWithoutShareToken operation.
     * @callback module:api/ReportsApi~getReportWithoutShareTokenCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns the report data of a specified report
     * @param {String} reportLongId long id of the report to return
     * @param {module:api/ReportsApi~getReportWithoutShareTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getReportWithoutShareToken(reportLongId, callback) {
      let postBody = null;
      // verify the required parameter 'reportLongId' is set
      if (reportLongId === undefined || reportLongId === null) {
        throw new Error("Missing the required parameter 'reportLongId' when calling getReportWithoutShareToken");
      }

      let pathParams = {
        'reportLongId': reportLongId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/report/{reportLongId}/data/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the hideReport operation.
     * @callback module:api/ReportsApi~hideReportCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Hides the report specified by the report long id
     * @param {String} reportLongId long id of the report to hide
     * @param {module:api/ReportsApi~hideReportCallback} callback The callback function, accepting three arguments: error, data, response
     */
    hideReport(reportLongId, callback) {
      let postBody = null;
      // verify the required parameter 'reportLongId' is set
      if (reportLongId === undefined || reportLongId === null) {
        throw new Error("Missing the required parameter 'reportLongId' when calling hideReport");
      }

      let pathParams = {
        'reportLongId': reportLongId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/report/{reportLongId}/hide/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the moveReport operation.
     * @callback module:api/ReportsApi~moveReportCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} reportLongId The long id of the report to move to another machine
     * @param {module:model/MoveReportQuery} moveReportQuery The Query contains the serial of the target machine
     * @param {module:api/ReportsApi~moveReportCallback} callback The callback function, accepting three arguments: error, data, response
     */
    moveReport(reportLongId, moveReportQuery, callback) {
      let postBody = moveReportQuery;
      // verify the required parameter 'reportLongId' is set
      if (reportLongId === undefined || reportLongId === null) {
        throw new Error("Missing the required parameter 'reportLongId' when calling moveReport");
      }
      // verify the required parameter 'moveReportQuery' is set
      if (moveReportQuery === undefined || moveReportQuery === null) {
        throw new Error("Missing the required parameter 'moveReportQuery' when calling moveReport");
      }

      let pathParams = {
        'reportLongId': reportLongId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/report/{reportLongId}/move', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the revokeReportShareToken operation.
     * @callback module:api/ReportsApi~revokeReportShareTokenCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/RevokeReportShareTokenQuery} revokeReportShareTokenQuery The Query with the information of which token to revoke
     * @param {module:api/ReportsApi~revokeReportShareTokenCallback} callback The callback function, accepting three arguments: error, data, response
     */
    revokeReportShareToken(revokeReportShareTokenQuery, callback) {
      let postBody = revokeReportShareTokenQuery;
      // verify the required parameter 'revokeReportShareTokenQuery' is set
      if (revokeReportShareTokenQuery === undefined || revokeReportShareTokenQuery === null) {
        throw new Error("Missing the required parameter 'revokeReportShareTokenQuery' when calling revokeReportShareToken");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/report/token/revoke/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the unhideReport operation.
     * @callback module:api/ReportsApi~unhideReportCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Unhides the report specified by the report long id
     * @param {String} reportLongId long id of the report to unhide
     * @param {module:api/ReportsApi~unhideReportCallback} callback The callback function, accepting three arguments: error, data, response
     */
    unhideReport(reportLongId, callback) {
      let postBody = null;
      // verify the required parameter 'reportLongId' is set
      if (reportLongId === undefined || reportLongId === null) {
        throw new Error("Missing the required parameter 'reportLongId' when calling unhideReport");
      }

      let pathParams = {
        'reportLongId': reportLongId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/report/{reportLongId}/unhide/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateReportShareToken operation.
     * @callback module:api/ReportsApi~updateReportShareTokenCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/EditReportShareTokenQuery} editReportShareTokenQuery The Query with the token information to edit
     * @param {module:api/ReportsApi~updateReportShareTokenCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateReportShareToken(editReportShareTokenQuery, callback) {
      let postBody = editReportShareTokenQuery;
      // verify the required parameter 'editReportShareTokenQuery' is set
      if (editReportShareTokenQuery === undefined || editReportShareTokenQuery === null) {
        throw new Error("Missing the required parameter 'editReportShareTokenQuery' when calling updateReportShareToken");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/report/token/edit/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
