export default {
  prettifyType (type) {
    const capitalizeFirst = function (word) {
      if (word.length === 0) {
        return ''
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }

    return type.split('_').map(capitalizeFirst).join(' ')
  },
  shortenUuid (uuid) {
    return uuid.substr(0, 8)
  }
}
